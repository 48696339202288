<template>
<div >

    <div class="row mb-2">

        <div class="col-12 ">
            <h3 class="text-center font-weight-bolder">Geo Management Report </h3>
                    <b-button class="btn  btn-warning mr-2 text-white " size="sm"
                          @click="goback"><strong>Click Distribution
                    </strong></b-button>
                    <b-button class="btn  btn-info mr-2 text-white " size="sm"
                              @click="goback"><strong>Geo Configuration
                    </strong></b-button>
            <hr class="hr-header" data-content="Product Settings" />
        </div>
        <hr />

    </div>

    <div  class="row">
        <div class="col-12">
<h5 class='text-center pb-2 font-weight-bold'>Link/Domain: {{this.domain}}</h5>
            <ejs-grid
                ref="grid"
                id="Grid"
                :dataSource="geo"
                :allowPaging="true"
                :allowExcelExport='true'
                :pageSettings='pageSettings'
                :filterSettings="filterSettings"
                :allowSorting ='true'
                :allowFiltering="true"
                :allowGrouping="true"
                :dataBound='dataBound'
                :commandClick="commandClick"
            >
                <e-columns>
                    <e-column field='Country' headerText='Country' ></e-column>
                    <e-column field='State' headerText='State' details="'this is just is'" ></e-column>
                    <e-column field='City' headerText='City'></e-column>
                    <e-column field='CampaignStatus' headerText='Campaign Status'></e-column>
                    <e-column field='ClicksRequested' headerText='# Of Clicks Requested'></e-column>
                    <e-column field='ClicksDelivered' headerText='# Of Clicks Delivered'></e-column>
                    <e-column field='PercentageDelivered' headerText='% Of Clicks Delivered'></e-column>
<!--                    <e-column field='LinkDomain' headerText='Link/Domain'></e-column>-->
<!--                    <e-column field="Commands" headerText="Action" :commands="commands"></e-column>-->

                </e-columns>

            </ejs-grid>
        </div>

    </div>





</div>
</template>

<script>
// import axios from 'axios'
/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, CommandColumn } from "@syncfusion/ej2-vue-grids";


import Vue from "vue";
import axios from "axios";

// import swal from "sweetalert";

Vue.use(GridPlugin);

export default {
    name: "Geo.Management",
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter,CommandColumn ]
    },
    data() {

        return {
            items:[],
            domain:'',
            geo:[],
            report:[{
        Country:'A',State:'B', City:'C',CampaignStatus:'D',ClicksRequested:'E',
                ClicksDelivered:'F',  PercentageDelivered:'G', LinkDomain:'H'
                ,Commands:'I'
            }],
            commands: [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                ]
        }
    },
    created(){
        this.$root.preloader = false;
        // this.loader();
        this. getDomain();


    },

    computed:{


    },
    methods: {

        getDomain() {
            let request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/domain/manage/geo/${this.$route.query.client_id}`, {params:request}).then((resp) => {
                console.log(resp.data)
                const result = resp.data || []

                if(resp.data){
                    for(let i = 0; i < result.length; i++){

                        let clickpercentage =   (result[i].clicksdone / result[i].requiredclicks) * 100;

                        this.geo.push( {Country: result[i].country, State:result[i].state, LinkDomain:result[i].link,  City:result[i].city, CampaignStatus:result[i].status,
                            ClicksDelivered:result[i].clicksdone,ClicksRequested:result[i].requiredclicks, PercentageDelivered:  clickpercentage})
                        this.domain = result[0].link;
                    }
                }
            })
        },
        // loader: function(){
        //     var request = {'apikey': this.$root.apikey,sSearch:''};
        //
        //     return axios.get(`${this.$root.serverUrl}/admin/domain/manage/geo/${this.root.query.client_id}`, {params:request}).then(function(resp){
        //         //Store the stats
        //         console.log(resp)
        //         // this.items = (resp.data && resp.data.data)? resp.data.data : [];
        //     }.bind(this));
        // },
        goback(){
         return this.$router.go(-1)
        },
        commandClick: function(args) {
            alert(JSON.stringify(args.rowData));
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to}_capped_and_shown_report`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.report
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },


    }

}
</script>

<style scoped>

</style>
